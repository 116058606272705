<template>
	<div class="vote-item-info d-flex flex-column">
		<!-- Loader -->
		<v-progress-linear
			v-if="loading"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear>

		<!-- Header -->
		<div
			v-if="!loading"
			class="border pa-5 py-5 d-flex justify-space-between align-center"
		>
			<v-skeleton-loader
				v-if="loading"
				class="skeleton-header ma-0"
				width="130px"
				type="text"
			></v-skeleton-loader>

			<div v-else class="d-flex justify-space-between align-center">
				<span class="text-subtitle-1 font-weight-regular">
					{{ `Голосование №${item.pretty_id}` }}
				</span>
				<span
					v-if="item.status"
					class="primary--text ml-4 font-weight-bold text-subtitle-1"
				>
					{{ item.status.name }}
				</span>
			</div>

			<div class="d-flex">
				<!-- Удалить -->
				<v-btn
					class="text-subtitle-2 text-capitalize mr-5 px-3"
					color="red"
					outlined
					@click="showConfirmModal = true"
				>
					Удалить
				</v-btn>
				<!-- Редактировать -->
				<v-btn
					class="text-subtitle-2 text-capitalize ma-0 px-3"
					color="primary"
					outlined
					:loading="isPublishing"
					@click="showEditModal = true"
				>
					Редактировать
				</v-btn>
			</div>
		</div>

		<!-- Body -->
		<div v-if="!loading" class="body">
			<!-- Левая часть -->
			<div class="body-left border-right">
				<div class="body-info pa-5 border-bottom">
					<!-- Адрес -->
					<div class="d-flex flex-column mr-7">
						<span class="text-info-name">Адрес</span>
						<v-skeleton-loader v-if="loading" width="180px" type="text">
						</v-skeleton-loader>
						<span v-else class="text-info-field">
							{{ item.house.apartment_address }}
						</span>
					</div>
					<!-- Дата создания -->
					<div class="d-flex flex-column">
						<span class="text-info-name">Начало голосования</span>
						<v-skeleton-loader v-if="loading" width="180px" type="text">
						</v-skeleton-loader>
						<span v-else-if="item.date_begin" class="text-info-field">
							{{ item.date_begin | timestampToDdmmyyyy({showTime: true}) }}
						</span>
						<span v-else>---</span>
					</div>
					<!-- Дата завершения -->
					<div class="d-flex flex-column">
						<span class="text-info-name">Завершение голосования</span>
						<v-skeleton-loader v-if="loading" width="180px" type="text">
						</v-skeleton-loader>
						<span v-else-if="item.date_end" class="text-info-field">
							{{ item.date_end | timestampToDdmmyyyy({showTime: true}) }}
						</span>
						<span v-else>---</span>
					</div>
				</div>
				<div class="body-info pa-5 border-bottom">
					<div class="d-flex flex-column">
						<span class="text-info-name">Ответственные за голосование</span>
						<v-skeleton-loader v-if="loading" width="180px" type="text">
						</v-skeleton-loader>
						<span v-else class="text-info-field">
							<span v-for="(resp, i) in item.responsible_voting">
								{{ resp.name }} {{ i+1 < item.responsible_voting.length ? "," : ''  }}
							</span>
						</span>
					</div>
					<div class="d-flex flex-column"></div>
					<div class="d-flex flex-column">
						<span class="text-info-name">РКА</span>
						<v-skeleton-loader v-if="loading" width="180px" type="text">
						</v-skeleton-loader>
						<span v-else class="text-info-field">
							{{ item.house.rca }}
						</span>
					</div>
				</div>
				<div class="body-info pa-5 border-bottom">
					<div class="d-flex flex-column">
						<span class="text-info-name">Категории помещений</span>
						<ChipsGroupMultiple
							:chips="catalog.room_types"
							:activeChipID.sync="item.room_types"
							:activeChip.sync="roomTypeIndex"
							:borderAll="false"
							:disabled="true"
							:showArrow="catalog.room_types.length > 4"
						/>
					</div>
				</div>
				<div v-for="(q, i) in item.questions" :key="i" class="body-questions pa-5 border-bottom">
					<span class="text-info-name segoeui-grey">
						Вопрос {{ i+1 }} из {{ item.questions.length }}
					</span>
					<p class="text-info-field mt-2 segoeui-dark">{{ q.description }}</p>
					<div v-if="item.status.sys_name !== 'active' && item.status.sys_name !== 'done'">
						<span class="text-info-name segoeui-grey ft-14 ml-7">Варианты ответов</span>
						<p v-for="(option, ind) in q.options" :key="ind" class="ml-7 q-options segoeui ma-0 mt-2 d-flex align-center">
							<v-icon color="grey" class="mr-2" small>mdi-circle-outline</v-icon>
							{{ option.option }}
						</p>
					</div>
					<div v-else class="results">
						<p class="text-subtitle segoeui-grey ft-14">Результаты голосования:</p>
						<template v-for="opt in q.options">
							<div class="option-text ft-14">{{ opt.option }}</div>
							<div class="bar-container">
								<div class="bar">
									<div class="online" :style="{width: opt.percent < 10 && opt.percent !== 0 ? '10%' : opt.percent + '%'}"><span v-if="opt.percent">{{ Math.round(opt.percent) }}%</span></div>
									<div class="offline" :style="{width: opt.offline_percent < 10 && opt.offline_percent !== 0 ? '10%' : opt.offline_percent + '%'}"><span v-if="opt.offline_percent">{{ Math.round(opt.offline_percent) }}%</span></div>
								</div>
							</div>
							<div class="info-block">
								<div><div class="circle online-circle"></div><span>Онлайн - {{ opt.count }}</span></div>
								<div><div class="circle offline-circle mr-1"></div>Письменно
									<span v-if="opt.offline_count && !opt.edit"> - {{ opt.offline_count }}</span>
									<v-btn v-if="opt.offline_count && !opt.edit" icon plain small @click="(opt.edit = true, opt.offline_count = '')" max-height="10">
										<v-icon small>mdi-close</v-icon>
									</v-btn>
									<span v-else class="d-flex">
										<v-text-field
											v-model="opt.offline_count"
											placeholder="кол-во"
											dense
											maxLength="3"
											hide-details
											flat
											solo
											@keypress="isNumber"
											class="border-all amount-votes"
											:error="amountOfflineVotes.length < 1"
										>
										</v-text-field>
										<v-btn text small class="pa-0" style="min-width: 35px;" @click="setVoteOfflineCount(opt.id, q.id, opt.offline_count)" :disabled="!opt.offline_count">OK</v-btn>
									</span>
								</div>
								<div>ИТОГО: {{ calculateVotePercentage(opt.count + opt.offline_count, item.room_have_vote_count) }} ({{ opt.count + opt.offline_count }} из {{ item.room_have_vote_count }})</div>
							</div>
						</template>
						<div class="info-block" style="justify-content: flex-end;">
							<div>Проголосовало: 
								<span><strong>{{ calculateVotePercentage(item.room_vote_count + item.room_vote_offline_count, item.room_have_vote_count) }}</strong></span> 
								<span>({{ item.room_vote_count + item.room_vote_offline_count }} из {{ item.room_have_vote_count }})</span>
							</div>
						</div>
					</div>
					<div class="ml-3 mt-4">
						<ImagesList :images="imagesInfo[i]" :is_comment="true" />
						<v-row>
							<v-col v-for="(file, i) in q.files" v-if="file.type !== 'image'" :key="i" cols="auto" class="position-relative file-container">
								<div class="image-wrapper">
									<img src="@/assets/file.svg" class="notify-image">
									<p class="ma-0">
										<a @click="downloadFile(file)" class="ft-12">{{ file.name }}</a>
									</p>
								</div>
							</v-col>
						</v-row>
					</div>
				</div>
				<div v-if="!loading" class="body-apartments pa-5">
					<div class="block-info mt-3">
						<span class="segoeui-grey">Помещения участвующие в голосовании</span>
					</div>
					<v-row class="mb-6 mt-4">
						<v-col cols="12" md="6" v-for="(item, i) in gdbrnInfo" :key="i">
							<v-expansion-panels class="px-7">
								<v-expansion-panel class="gdbrn-info">
									<v-expansion-panel-header>
										Количество {{ item.sys_name === 'apartment' ? 'квартир' : item.sys_name === 'non-residential-premises' ? 'НП' : 'паркомест'  }} 
										<v-spacer></v-spacer>
										<span class="amount-rooms">{{ item.gbdrn_room_count }}</span>
										
									</v-expansion-panel-header>
									<v-expansion-panel-content class="pa-0">
										<v-data-table
											class="overflow-x gbdrn-table mb-2"
											height="100%"
											item-key="id"
											hide-default-footer
											:headers="headers"
											:server-items-length="10"
											:items="filteredRooms(item, i)"
										>
										<template v-slot:top>
											<v-toolbar flat style="background: #F1F1F1;">
												<v-row>
													<v-col cols="10" class="align-center d-flex px-0 pl-2">
														<v-text-field
															v-model="filterRooms[i]"
															class="border-all room"
															solo
															dense
															rounded
															flat
															maxLength="4"
															hide-details
															append-icon="mdi-magnify"
															placeholder="Поиск по номеру помещения"
														>
														</v-text-field>
													</v-col>
													<v-col cols="2" class="px-0 d-flex justify-center">
														<v-menu
															bottom
															:offset-x="true"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-btn icon size="small" v-bind="attrs"v-on="on">
																	<v-icon>mdi-filter-outline</v-icon>
																</v-btn>
															</template>
															<v-list>
																<v-radio-group @change="filterVoted($event, i)" class="mt-0" hide-details>
																	<v-list-item>
																		<v-radio
																			class="rad"
																			label="Проголосовали онлайн"
																			value="true"
																		></v-radio>
																	</v-list-item>
																	<v-list-item>
																		<v-radio
																			class="rad"
																			label="Не проголосовали онлайн"
																			value="false"
																		></v-radio>
																	</v-list-item>
																</v-radio-group>
																<div class="d-flex justify-center mt-2">
																	<v-btn color="primary" outlined class="text-none" @click="filterVoted('clear', i)">Очистить</v-btn>
																</div>
															</v-list>
														</v-menu>
													</v-col>
												</v-row>
												
											</v-toolbar>
										</template>
										<template v-slot:[`header.number`]>
												<span class="segoeui-dark text-table-center">№ пом</span>
										</template>
										<template v-slot:[`header.rca`]>
												<span class="segoeui-dark text-table-center">РКА</span>
										</template>
										<template v-slot:[`header.voted`]>
												<span class="segoeui-dark text-table-center">Онлайн голос</span>
										</template>
												
										<template v-slot:[`item.number`]="{ item }">
											<td>
												<span class="font-weight-bold segoeui-dark">{{item.number}}</span>
											</td>
										</template>
										<template v-slot:[`item.rca`]="{ item }">
											<td class="d-flex justify-center align-center">
												<span class="segoeui-dark">{{item.rca}}</span>
											</td>
										</template>
										<template v-slot:[`item.voted`]="{ item }">
											<td class="d-flex justify-center align-center">
												<v-icon v-if="!item.voted">mdi-minus</v-icon>
												<v-icon v-else color="primary">mdi-circle</v-icon>
											</td>
										</template>

										</v-data-table>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
					</v-row>

				</div>
				<!-- Alert component -->
				<div v-if="alert" class="pa-5" ref="alert">
					<v-alert :type="alert.color" dense outlined>
						{{ alert.message }}
					</v-alert>
				</div>
			</div>
			<!-- Правая часть -->
			<div class="body-right pa-5">
				<p class="segoeui-dark font-weight-bold ft-14">Письменные листы голосования</p>
				<div v-if="item.list_offline_archive" class="image-wrapper">
					<img src="@/assets/file.svg" class="notify-image">
					<p class="ma-0 d-flex">
						<span class="ft-8 segoeui-lightgrey">{{ truncatedName(item.list_offline_archive.name) }}</span>
					</p>
					<v-btn
						@click="deleteList"
						color="white"
						small
						class="delete-btn pa-0"
						min-width="24"
						min-height="24"
						elevation="0"
					>
						<v-icon color="red">mdi-close</v-icon>
					</v-btn>
					<v-btn @click="downloadFile(item.list_offline_archive)" outlined color="primary" small class="text-none segoeui spacing-normal mt-2">Скачать</v-btn>
				</div>
				<file-input
					v-else
					@change="handleEditFileChange($event)"
					accept=".pdf"
				></file-input>
				<p v-if="lists.data && (item.status.sys_name === 'active' || item.status.sys_name === 'done')" class="segoeui-dark font-weight-bold ft-14 mt-7">Онлайн листы голосования</p>
				<div v-if="lists.data && (item.status.sys_name === 'active' || item.status.sys_name === 'done')" class="image-wrapper">
					<img src="@/assets/file.svg" class="notify-image">
					<p class="ma-0 d-flex">
						<span class="ft-8 segoeui-lightgrey">{{ truncatedName(lists.data.name) }}</span>
					</p>
					<v-btn @click="downloadFile(lists.data)" outlined color="primary" small class="text-none segoeui spacing-normal mt-2">Скачать</v-btn>
				</div>
				<p v-if="item.status.sys_name === 'done'" class="segoeui-dark font-weight-bold ft-14 mt-7">Протокол результатов голосования</p>
				<div v-if="item.status.sys_name === 'done'">
					<img src="@/assets/file.svg" class="notify-image">
					<p class="ma-0 d-flex">
						<span class="ft-8 segoeui-lightgrey">Официальный про...</span>
					</p>
					<v-btn @click="generateProtocol" outlined color="primary" :loading="isGenerateLoading" small class="text-none segoeui spacing-normal mt-2">Скачать</v-btn>
				</div>
			</div>
		</div>
		<!-- Редактирование информации пользователя -->
		<VoteNewTableModal
			v-if="showEditModal"
			:modalData="item"
			@update-info="closeModal(true)"
			@close-modal="closeModal"
		/>
		<!-- Подтверждение удаления пользователя -->
		<ModalConfirm
			v-if="showConfirmModal"
			:title="'Удаление голосования'"
			@close-modal="deleteVote"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span>Вы действительно хотите удалить голосование</span>
					<strong>{{ `Голосование ${item.pretty_id}` }}?</strong>
				</div>
			</template>
		</ModalConfirm>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import ImagesList from "@/components/app/ImagesList";
import ModalConfirm from "@/components/app/ModalConfirm";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import ChipsGroupMultiple from "@/components/app/ChipsGroupMultiple";
import VoteNewTableModal from "@/components/votes/VoteNewTableModal";
import VoteItemInfoRight from "@/components/VoteItemInfoRight";
import FileInput from '@/components/app/FileInput.vue';

export default {
	name: "TenantsItemInfo",
	components: {
		DatePickerPlain,
		ModalConfirm,
		ImagesList,
		FileInput,
		VoteNewTableModal,
		VoteItemInfoRight,
		ChipsGroupMultiple,
	},
	data() {
		return {
			alert: null,
			id: this.$route.params.id,
			item: null,
			optIdEdit: '',
			amountOfflineVotes: '',
			gdbrnInfo: [],
			roomTypeIndex: [],
			fio: "",
			filterRooms: [],
			imagesInfo: [],
			loading: true,
			toSaveChanges: false,
			isGenerateLoading: false,
			isSavingChanges: false,
			showConfirmModal: false,
			showEditModal: false,
			showBlockModal: false,
			isPublishing: false,
			lists: {
				data: null
			},
			headers: [
				{
					text: "№ пом",
					value: "number",
					sortable: false,
				},
				{
					text: "РКА",
					value: "rca",
					sortable: false,
				},
				{
					text: "Онлайн голос",
					value: "voted",
					sortable: false,
				},
			]
		};
	},
	computed: {
		...mapGetters({
			ksk_info: "ksk/GET_KSK_INFO",
			houses: "ksk/GET_HOUSES",
			catalog: "catalog/GET_CATALOG",
		}),
	},
	watch: {
		// ? Ручное отслеживание маршрута, чтобы обновлять данные о сотрудниках
		"$route"() {
			this.filterRooms = []
			this.gdbrnInfo = []
			this.id = this.$route.params.id;
			this.loadData();
			this.loadLists();
		},
		alert(val) {
			if (val) {
				this.$nextTick(() =>
					this.$refs.alert.scrollIntoView({ behavior: "smooth" })
				);
				setTimeout(() => (this.alert = null), 5000);
			}
		},
		item: {
			handler(newVal, oldVal) {
				this.toSaveChanges = true;
			},
			deep: true,
		},
	},
	methods: {
		isNumber(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
		truncatedName(name) {
      const maxLength = 17;
      return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
    },
		calculateVotePercentage(voteCount, haveVoteCount) {
      if (!haveVoteCount) return '0%';
      const percentage = (voteCount / haveVoteCount) * 100;
      return `${percentage.toFixed(1)}%`;
    },
		filterVoted(status, index) {
			console.log('e', status)
			console.log('index', index)
			this.loadGdbrnInfo(this.gdbrnInfo[index]._id, index, status)
		},
		filteredRooms(item, index) {
      const filter = this.filterRooms[index] || '';
      return item.gdbrn_rooms.filter(room => {
        return room.number.toString().includes(filter);
      });
    },
		async closeModal(isConfirmed) {
			if (isConfirmed) {
				this.loadData();
			}
			this.showEditModal = false;
		},

		closeModalBlock() {
			this.showBlockModal = false;
		},
		async handleEditFileChange(event) {
			const files = event;
			console.log('event', files);

			if (!files.length) {
				return;
			}

			const formData = new FormData();
			for (let i = 0; i < files.length; i++) {
				formData.append('lists[]', files[i]);
			}

			try {
				await this.$api.ksk.add_vote_offline_lists(formData, this.id);
				this.loadData()
			} catch (error) {
				console.log('err', error);
			}
		},
		async deleteVote(confirm) {
			this.alert = null;
			this.showConfirmModal = false;
			if (!confirm) return;
			try {
				const res = await this.$api.ksk.delete_vote_v3(this.$route.params.id);
				this.$root.snackbar.show({ color: "red", message: res.message || "Голосование удалено" });
				this.$router.push("/vote-new");
			} catch (error) {
				const message = "Не удалось удалить голосование. Попробуйте еще раз";
				this.alert = {
					color: "error",
					message: error.message || message,
				};
				console.error(error);
			}
		},
		async setVoteOfflineCount(optId, qId, amount) {
			await this.$api.ksk.set_offline_count({vote_offline: amount, _method: 'patch'}, qId, optId)
			this.optIdEdit = ''
			this.amountOfflineVotes = ''
			this.loadData('noLoad')
		},
		async loadData(mode) {
			if(mode !== 'noLoad') {
				this.loading = true;
			}
			console.log('this.id!', this.id)
			const res = await this.$api.ksk.load_vote_info_new(this.id);
			this.item = res.data
			this.roomTypeIndex = this.item.room_types.map(item => {
				return this.catalog.room_types.findIndex(catalogItem => catalogItem.pretty_id === item.pretty_id);
			});
			for (let i = 0; i < this.item.questions.length; i++) {
				this.imagesInfo.push([]);
				this.item.questions[i].files.forEach(element => {
					if(element.type === 'image') {
						this.imagesInfo[this.imagesInfo.length - 1].push(element);
					} else {
						// this.loadServerImage(element, i)
					}
				});
				let options = this.item.questions[i].options;
				for (let j = 0; j < options.length; j++) {
					if (options[j].offline_count === 0) {
						options[j].offline_count = '';
						options[j].edit = true;
					}
				}
			}
			this.gdbrnInfo = []
			this.item.room_types.forEach(el => {
				this.loadGdbrnInfo(el._id)
			});
			console.log("item", this.item);
			this.loading = false;
		},
		async generateProtocol() {
			try {
				this.isGenerateLoading = true
				const res = await this.$api.ksk.vote_generate_protocol(this.id)
				console.log('res', res)
				this.downloadFile(res)
				this.isGenerateLoading = false
			} catch (error) {
				console.log('err', err)
				this.$root.snackbar.show({ color: "red", message: err.message || "Произошла ошибка" });
				this.isGenerateLoading = false
			}
			
		},
		async loadLists() {
			try {
				this.lists = await this.$api.ksk.load_vote_lists(this.id)
			} catch (error) {
				console.log('err', error)
			}
		},
		async loadGdbrnInfo(type_id, index, voteStatus) {
			const params = {
				type_id: type_id
			}
			console.log('voteStatus', voteStatus)
			console.log('index', index)
			if (index !== undefined && index !== null) {
				params.voted = voteStatus;
			}
			if(params.voted === 'clear') {
				delete params.voted
			}
			const res = await this.$api.ksk.load_gdbrn_info(this.id, params);
			if(index !== undefined && index !== null) {
				this.gdbrnInfo = this.gdbrnInfo.map((item, i) => (i === index ? res : item));
			} else {
				this.gdbrnInfo.push(res)
			}
			console.log('gdbrnInfo', this.gdbrnInfo)
		},
		async loadServerImage(avatar, indexQ, showDefault = true) {
      if (!avatar && showDefault) {
        this.images.push("@/assets/user.svg");
        return; 
      }

      if (avatar?.id) {
        try {
          const response = await this.$api.ksk.load_file(avatar.id);
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const blobUrl = URL.createObjectURL(blob);
					this.item.questions[indexQ].files.push({url: blobUrl})
        } catch (error) {
          console.error('Ошибка при загрузке изображения:', error);
          this.images.push("@/assets/user.svg");
        }
      } else {
        this.imageSrc = require("@/assets/user.svg");
      }
    },
		async deleteList() {
			try {
				await this.$api.ksk.delete_offline_list(this.id)
				this.loadData()
			} catch (error) {
				console.error('Ошибка при удалении изображения:', error);
				this.alert = {
					color: 'error',
					message: 'Ошибка при удалении изображения:' + error,
				};
			}
		},
		async downloadFile(file) {
			const response = await this.$api.ksk.load_file(file.id);
			const blob = new Blob([response], { type: 'application/octet-stream' });
			const blobUrl = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = file.original_name;
			link.click();
			URL.revokeObjectURL(blobUrl);
		},
	},

	async mounted() {
		try {
			await this.loadData();
			await this.loadLists();
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.vote-item-info {
	height: 100vh;
	overflow: hidden;
}
.body {
	display: grid;
	grid-template-columns: 3fr 1fr;
	height: 100% !important;
	overflow: hidden;
}
.body-left {
	overflow: scroll;
}
.body-info {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	row-gap: 20px;
}

// Skeleton loader
::v-deep .v-skeleton-loader__heading,
::v-deep .skeleton-header .v-skeleton-loader__text {
	margin: 0 !important;
}
::v-deep .v-skeleton-loader__list-item {
	padding: 0 !important;
}
.block-info {
	background: #F1F1F1;
	padding: 8px 28px 8px 28px;
}
.block-info span {
	color: #5C6166;
	font-weight: 600;
	font-size: 16px;
}
.gdbrn-info {
	border-radius: 12px !important;
	border: 1px solid rgba(0, 0, 0, 0.22);
}
.gdbrn-info::before {
	box-shadow: none;
}
.amount-rooms {
	font-weight: 700;
	color: #8C9197;
	font-size: 16px;
	flex: none !important;
	margin-right: 10px;
}
.image-wrapper {
  position: relative;
  display: inline-block;
}
.delete-btn {
  position: absolute;
	top: 0px;
	right: 7px;
	border-radius: 0px 4px 0px 4px !important;
}
.file-container {
	max-width: 150px;
	margin-top: 10px;
	display: flex;
}

.image-container:hover .delete-btn {
  display: block;
}
.file-container:hover .delete-btn {
  display: block;
}


.results {
	margin-top: 10px;
	margin-right: 30px;
	margin-left: 30px;
	width: 70%;
}
.results h3 {
	font-size: 14px;
	margin: 0 0 5px;
}
.results .bar-container {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	position: relative;
}
.results .bar {
	display: flex;
	height: 20px;
	border-radius: 6px;
	overflow: hidden;
	width: 100%;
	background-color: #e0e0e0;
}
.results .bar div {
	height: 100%;
	line-height: 20px;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	position: relative;
}
.results .bar .online {
	background-color: #B5E5C5;
}
.results .bar .offline {
	background-color: #FBDFA4;
}

.results .bar div span {
	right: 2px;
	transform: none;
	color: #555;
	z-index: 2;
	display: flex;
	align-items: center;
	background: #E6E6E6;
	border-radius: 4px;
	padding: 1px 4px 1px 4px;
	position: absolute;
	top: 2px;
	bottom: 2px;
	font-size: 15px;
}
.results .info-block {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	margin-top: 5px;
	margin-bottom: 15px;
	color: #6D7379;
}
.results .info-block > div {
	flex-basis: 33%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
}
.results .info-block > div:nth-child(2) {
	justify-content: normal;
	white-space: nowrap;
}
.results .info-block > div:last-child {
	justify-content: flex-end;
}
.results .info-block div {
	display: flex;
	align-items: center;
}
.results .info-block div span {
	margin-left: 5px;
}
.results .info-block .circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	display: inline-block;
}
.results .info-block .online-circle {
	background-color: #B5E5C5;
}
.results .info-block .offline-circle {
	background-color: #FBDFA4;
}
</style>

<style>
.chips-placeholder .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
	max-height: 25px !important;
}
.text-info-name {
	color: #5C6166;
	font-weight: 400;
	font-size: 14px;
}
.text-info-field {
	color: #000000;
	font-weight: 400;
	font-size: 16px;
}

.q-options {
	color: #8F9091;
	font-size: 16px;
}
.gdbrn-info .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
.ft-12 {
	font-size: 12px;
}
.ft-10 {
	font-size: 10px;
}
.ft-8 {
	font-size: 8px;
}

.gbdrn-table .v-data-table__wrapper {
	height: 400px !important;
}
.text-table-center {
	display: block;
	width: 100%;
	text-align: center;
}
.gbdrn-table tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05) !important;
}
.gbdrn-table tbody tr:nth-of-type(even):not(:nth-child(1)) {
	background-color: #fff !important;
}
.theme--light.gbdrn-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: none;
}
.amount-votes {
	border-radius: 8px;
	width: 55px;
	min-height: 25px;
}
.amount-votes .v-input__slot {
	height: 25px;
}
.amount-votes.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 25px !important;
}
.amount-votes input {
	font-size: 12px;
	text-align: center;
	padding: 0 !important;
}
.amount-votes.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
	padding: 0 6px !important;
}
.spacing-normal {
	letter-spacing: normal !important;
}
.disabled-item {
	color: #B5B9BD !important;
}
.room .v-icon {
	color: #B5B9BD;
}
</style>